import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 76",
  week: "Semana 11",
  day: "28",
  month: "May",
  monthNumber: "05",
  date: "2020-05-28",
  path: "/cronologia/semana-11#dia-28-may/",
};

const Day76 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModDailyHighlight>
          Segundo día del periodo de luto oficial
        </ModDailyHighlight>
        <ModText>
          Los{" "}
          <strong>
            contagios y fallecimientos por COVID-19 en personal sanitario
          </strong>
          serán considerados como contingencia profesional derivada de accidente
          de trabajo. Así lo ha establecido el RDL aprobado por el Gobierno esta
          semana y que puedes consultar en el{" "}
          <InlineLink link="https://boe.es/diario_boe/txt.php?id=BOE-A-2020-5315">
            Boletín Oficial del Estado.
          </InlineLink>
        </ModText>
        <ModGraph name="liquidez-efectivo-cartera" alt="cartera, liquidez" />
        <ModText>
          Más de <strong>1,4 millones de autónomos/as </strong>cobrarán mañana
          la <strong>prestación extraordinaria</strong> por la crisis de
          COVID-19. Su cuantía será de, como mínimo, 661 euros mensuales, y
          además, conlleva la exoneración de cuotas a la Seguridad Social.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day76;
